






























































































































































































































































import Vue, { PropType } from 'vue'

import MessageBus from '@/message-bus'
import { UserCustomItemType } from '@/api'

// components
import FilterForm from './FilterForm.vue'
import {
  amountFields,
  CategoryField,
  categoryFields,
  Chart,
  chartTypes,
} from '@/components/charts/chart-settings'

// utils
import { sortAlpha } from '@/utils/sort'

// types
import { UserCustomItem } from '@/types'

const USER_CUSTOM_ITEM_TYPE: UserCustomItemType = 'explorer_chart'

export default Vue.extend({
  components: {
    FilterForm,
  },
  props: {
    value: Object as PropType<Chart>,
  },
  data: () => ({
    chartTypes: chartTypes,
    amountFields: amountFields,
    categoryFields: categoryFields,

    dialogJson: false,
    dialogAddView: false,

    userCustomItems: [] as UserCustomItem[],
    userCustomItem: undefined as UserCustomItem | undefined,
  }),
  computed: {
    sortedCategoryFields(): CategoryField[] {
      return this.categoryFields.slice().sort(sortAlpha('label'))
    },
  },
  methods: {
    onChangeUserCustomItem(v: UserCustomItem): void {
      if (!!v && v.json) this.$emit('input', v.json)
    },
    createView(): Promise<void> | void {
      const name = window.prompt('View Name:')

      if (name) {
        return this.$api.users
          .customItems(USER_CUSTOM_ITEM_TYPE)
          .create({
            name,
            json: this.value,
          })
          .then(v => {
            MessageBus.success(`saved ${v.name}!`)
            this.userCustomItems.push(v)
            this.userCustomItem = v
          })
          .catch(err => {
            MessageBus.error(err.message)
            throw err
          })
      }
    },
    replaceView(): Promise<void> | void {
      if (!this.userCustomItem || !this.userCustomItem.id) {
        MessageBus.error('view not selected')
        throw new Error('view not selected')
      }

      if (window.confirm(`Update "${this.userCustomItem.name}"?`)) {
        return this.$api.users
          .customItems(USER_CUSTOM_ITEM_TYPE)
          .replace(this.userCustomItem.id, {
            ...this.userCustomItem,
            json: this.value,
          })
          .then(v => {
            if (!this.userCustomItem) throw new Error('view missing')
            const id = this.userCustomItem.id
            MessageBus.success('saved!')
            const i = this.userCustomItems.findIndex(z => z.id === id)
            this.$set(this.userCustomItems, i, v)
            this.userCustomItem = v
          })
          .catch(err => {
            MessageBus.error(err.message)
            throw err
          })
      }
    },
    deleteView(): Promise<void> | void {
      if (!this.userCustomItem || !this.userCustomItem.id) {
        MessageBus.error('view not selected')
        throw new Error('view not selected')
      }
      if (
        window.confirm(
          `Are you sure you want to delete "${this.userCustomItem.name}"?`
        )
      ) {
        return this.$api.users
          .customItems(USER_CUSTOM_ITEM_TYPE)
          .delete(this.userCustomItem.id)
          .then(() => {
            if (!this.userCustomItem) throw new Error('view missing')
            const id = this.userCustomItem.id
            MessageBus.success('deleted!')
            const i = this.userCustomItems.findIndex(z => z.id === id)
            this.userCustomItem = undefined
            this.$delete(this.userCustomItems, i)
          })
          .catch(err => {
            MessageBus.error(err.message)
            throw err
          })
      }
    },

    closeDialog(): void {
      this.$emit('close')
    },
    applyDialog(): void {
      this.$emit('apply', this.value)
    },

    fetchUserCustomItems(): void {
      this.$api.users
        .customItems(USER_CUSTOM_ITEM_TYPE)
        .list()
        .then(items => {
          this.userCustomItems = items
        })
        .catch(err => {
          MessageBus.error(err.message)
        })
    },
  },
  created() {
    this.fetchUserCustomItems()
  },
})
