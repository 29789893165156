













































































































import Vue from 'vue'

import cloneDeep from 'lodash.clonedeep'

import ExplorerChartOptions from '@/components/explorer/ChartExplorerOptions.vue'
import PivotBarChart from '@/components/charts/PivotBarChart.vue'
import JQueryChips from '@/components/controls/JQueryChips.vue'

import {
  Chart,
  amountFields,
  categoryFields,
  DEFAULT_FINDER,
  DEFAULT_AMOUNT_FORMATTER,
  AmountField,
  CategoryField,
} from '@/components/charts/chart-settings'

import {
  encodeConfig,
  decodeConfig,
} from '@/components/explorer/explorer-encoder'

const DEFAULT_AMOUNT_FIELD_ID = 'postedAmount'
const DEFAULT_ROW_FIELD_ID = 'commissionPayPeriodId'

export default Vue.extend({
  metaInfo: {
    title: 'Chart Explorer',
  },
  components: {
    ExplorerChartOptions,
    PivotBarChart,
    JQueryChips,
  },
  data() {
    return {
      chart: {
        type: 'bar',
        cumulative: false,
        horizontal: false,
        stacked: false,
        showDataLabels: false,

        sortDesc: false,

        where: {
          isPosted: [1],
          salespersonId: [this.$auth.user?.id],
          commissionQuotaPeriodId: [new Date().getUTCFullYear()],
        },

        amountFieldId: DEFAULT_AMOUNT_FIELD_ID,
        rowFieldId: DEFAULT_ROW_FIELD_ID,
      } as Chart,

      editChart: undefined as Chart | undefined,

      showChart: true,
      showTable: false,

      loading: false,

      DEFAULT_FINDER: DEFAULT_FINDER,
      DEFAULT_AMOUNT_FORMATTER: DEFAULT_AMOUNT_FORMATTER,
    }
  },
  computed: {
    dialog(): boolean {
      return !!this.editChart
    },
    chartIsValid(): boolean {
      return (
        this.chart &&
        Object.keys(this.chart).length > 0 &&
        !!this.amountField &&
        !!this.rowField
      )
    },

    amountField(): AmountField | undefined {
      return amountFields.find(v => v.id === this.chart.amountFieldId)
    },
    rowField(): CategoryField | undefined {
      return categoryFields.find(v => v.id === this.chart.rowFieldId)
    },
    columnField(): CategoryField | undefined {
      return categoryFields.find(v => v.id === this.chart.columnFieldId)
    },
  },
  created() {
    if (
      this.$route.query &&
      this.$route.query.v &&
      typeof this.$route.query.v === 'string'
    )
      this.applyConfig(decodeConfig(this.$route.query.v))
  },
  methods: {
    openDialog(): void {
      this.editChart = cloneDeep(this.chart)
    },
    closeDialog(): void {
      this.editChart = undefined
    },
    applyConfig(v: Chart) {
      this.chart = v
      return Promise.resolve()
    },
    applyDialog(v: Chart, close = true): void {
      this.$router.replace({ query: { v: encodeConfig(v) } })
      this.applyConfig(v).then(() => {
        if (close) this.closeDialog()
      })
    },
    refresh(): void {
      const chart = this.$refs.chart as any // eslint-disable-line @typescript-eslint/no-explicit-any
      chart.refresh()
    },
  },
})
