var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center flex-wrap",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5 mr-3 text-no-wrap"},[_vm._v("Chart Explorer")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',[_vm._v("$chart")])]},proxy:true}]),model:{value:(_vm.showChart),callback:function ($$v) {_vm.showChart=$$v},expression:"showChart"}}),_c('v-divider',{staticClass:"mx-4",staticStyle:{"height":"36px"},attrs:{"vertical":""}}),_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',[_vm._v("$table")])]},proxy:true}]),model:{value:(_vm.showTable),callback:function ($$v) {_vm.showTable=$$v},expression:"showTable"}}),_c('v-divider',{staticClass:"ml-4",staticStyle:{"height":"36px","margin-right":"10px"},attrs:{"vertical":""}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',[_vm._v("$refresh")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',[_vm._v("$settings")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-3 pb-3",attrs:{"loading":_vm.loading}},[_c('v-card-text',[_c('j-query-chips',{attrs:{"query":_vm.chart.where}})],1),_c('pivot-bar-chart',{ref:"chart",attrs:{"chart-type":_vm.chart.type,"cumulative":_vm.chart.cumulative,"horizontal":_vm.chart.horizontal,"stacked":_vm.chart.stacked,"show-data-labels":_vm.chart.showDataLabels,"sort-value-fn":_vm.rowField && _vm.rowField.sorter ? _vm.rowField.sorter : undefined,"sort-desc":_vm.chart.sortDesc,"where":_vm.chart.where,"amount-field":_vm.amountField.id,"amount-label":_vm.amountField.label,"amount-formatter":_vm.amountField.formatter
              ? _vm.amountField.formatter(_vm.chart.where)
              : _vm.DEFAULT_AMOUNT_FORMATTER,"axis-amount-formatter":_vm.amountField.axisFormatter
              ? _vm.amountField.axisFormatter(_vm.chart.where)
              : _vm.DEFAULT_AMOUNT_FORMATTER,"data-label-amount-formatter":_vm.amountField.dataLabelFormatter
              ? _vm.amountField.dataLabelFormatter(_vm.chart.where)
              : _vm.DEFAULT_AMOUNT_FORMATTER,"categories":_vm.rowField.categories ? _vm.rowField.categories(_vm.chart.where) : undefined,"row-field":_vm.chart.rowFieldId,"row-label":_vm.rowField.label,"row-finder":_vm.rowField.finder || _vm.DEFAULT_FINDER,"column-field":_vm.chart.columnFieldId,"column-finder":_vm.columnField && _vm.columnField.id
              ? _vm.columnField.finder || _vm.DEFAULT_FINDER
              : undefined,"hide-chart":!_vm.showChart,"show-table":_vm.showTable,"markers-size":_vm.chart.markersSize,"stroke-curve":_vm.chart.strokeCurve,"stroke-width":_vm.chart.strokeWidth,"stroke-dash-array":_vm.chart.strokeDashArray},on:{"loading":function (v) { return (_vm.loading = v); }}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"1200"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.editChart)?_c('explorer-chart-options',{on:{"close":_vm.closeDialog,"apply":_vm.applyDialog},model:{value:(_vm.editChart),callback:function ($$v) {_vm.editChart=$$v},expression:"editChart"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }